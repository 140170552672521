/**
 * Logout Page
 * 
 * Log out a user and return them to the login page
 */

import React from 'react'
import { navigate } from 'gatsby'
import { setUser } from '../utils/auth'
import AppContext from '../utils/context'
import { setHuntData } from '../utils/hunt-data'
import { setTeamData } from '../utils/team-data'

export default class LogoutPage extends React.Component {
  componentDidMount() {
    //console.log(`Logging out...`)
    localStorage.clear()
    this.setLoading(false)
    navigate(`/login`)
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return <p>Logging out...</p>
        }}
      </AppContext.Consumer>
    )
  }
}
